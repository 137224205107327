import React, { useState } from 'react';
import "../assets/scss/_registration.scss"
import "../assets/scss/_components.scss"
import { useNavigate } from "react-router-dom";
import { useValidForm } from "../hooks/useValidForm";
import Rocket from "../assets/images/registration/Rocket.png"
import { useAuthenticate } from "../hooks/useAuthenticate";
import LoadingModal from "../components/modals/LoadingModal";
import { useAccount } from 'wagmi'
import { initializeDfo } from "../controllers/RegistrationController";
import { Input, Button, message } from "antd";
import FileUpload from '../components/elements/FileUpload';

export default function Registration() {
    const [dfoName, setDfoName] = useState("");
    const dfoTicker = useValidForm('', 2);
    const navigate = useNavigate();
    const { address, chain } = useAccount();
    const [imageFile, setImageFile] = useState(null);

    const { authenticate } = useAuthenticate();
    const [errorText, setErrorText] = useState("");
    const [Loading, setLoading] = useState(false);

    const[referralAddress, setReferralAddress] = useState("0x0000000000000000000000000000000000000000");

    function validateRegistration() {
        dfoName === "" && setErrorText("Must not be empty");
        if (!address) {
            message.error("Wallet not detected. Please connect your wallet to proceed.");
        }
        if (errorText === "" && imageFile !== null && address) {
            init(dfoName, address, referralAddress);
        }
    }

    async function init(_companyName, owner, referralAddress) {
        try {
            const dfoType = 'public';
            const response = await initializeDfo(_companyName, owner, dfoTicker.value, dfoType, imageFile, chain.network, referralAddress);

            const statusCode = response.status;

            setLoading(true);

            setTimeout(() => {
                setLoading(false);
                if (statusCode === 200) {
                    navigate('/feedback?id=' + _companyName);
                }
            }, 1500);
        } catch (error) {
            console.error('Error during initialization:', error);
            if (error.response && error.response.status === 403) {
                message.error("Project with title " + _companyName + " already exists");
            } else if (error.response && error.response.status === 401) {
                message.error('Wallet not authorized, please sign the message');
                await authenticate(address);
            } else {
                message.error('Error during initialization:' + error);
            }
        }
    }

    const handleSetDfoName = e => {
        setDfoName(e.target.value);
        setErrorText("");
    };

    return (
        <>
            <div className="registration-image">
                <div className="-title">List your project for free today</div>
            </div>
            <div className='registration-wrapper'>
                <img src={Rocket} className="-rocket-image" alt="" />
                <div className="-title">Submit Your Project!</div>
                <div>
                    <div className="-text">
                        Title
                    </div>
                    <Input
                        placeholder='Project name'
                        value={dfoName}
                        onChange={e => handleSetDfoName(e)}
                    />
                    <div className='-valid-msg'>{errorText}</div>
                </div>
                <div>
                    <div className="-text">Upload Project Logo</div>
                    <FileUpload  type='def' onFileChange={setImageFile}/>
                </div>
                <div>
                    <Button type="primary" onClick={() => validateRegistration()}>Submit</Button>
                </div>
                <LoadingModal show={Loading} text='Registration in process...' />
            </div>
        </>
    );
}
