import { useState } from 'react'
import { useAccount } from 'wagmi'
import { Card, Select, Button } from 'antd'
import { SwapOutlined } from '@ant-design/icons'
import PolygonMumbai from "../../assets/icons/header/polygonMumbai.svg";
import Binance from "../../assets/icons/header/binance.svg";
import Ethereum from "../../assets/icons/header/ethereum.svg";
import Optimism from "../../assets/icons/header/optimism.svg";
import Arbitrum from "../../assets/icons/arbitrum.svg";
import Zksync from "../../assets/icons/header/zksync.svg";
import Base from "../../assets/icons/header/base.svg";
import Sonic from "../../assets/icons/header/sonic.svg";
import Linea from "../../assets/icons/header/linea.svg";

const isProd = process.env.REACT_APP_ENV === "PROD";
 
const chains = {
  arbitrum: { icon: Arbitrum, label: "Arbitrum" },
  "arbitrum-goerli": { icon: Arbitrum, label: "Arbitrum" },
  homestead: { icon: Ethereum, label: "Ethereum" },
  goerli: { icon: Ethereum, label: "Ethereum" },
  bsc: { icon: Binance, label: "Binance" },
  matic: { icon: PolygonMumbai, label: "Polygon" },
  "optimism-goerli": { icon: Optimism, label: "Optimism" },
  optimism: { icon: Optimism, label: "Optimism" },
  zksync: { icon: Zksync, label: "Zksync" },
  linea: { icon: Linea, label: "Linea" },
  base: { icon: Base, label: "Base" },
  sonic: { icon: Sonic, label: "Sonic" },
};

export default function DFOPage({ hasCreationToken = true, currentChainId }) {
  const [selectedChain, setSelectedChain] = useState(null);
  const { chain } = useAccount();

  const availableChains = Object.entries(chains)
    .filter(([chainId]) => {
      if (isProd) {
        return chainId !== "optimism-goerli" &&
          chainId !== "goerli" &&
          chainId !== "arbitrum-goerli" &&
          chainId !== currentChainId &&
          chainId !== chain?.name?.toLowerCase();
      }
      return chainId !== "optimism" &&
        chainId !== "homestead" &&
        chainId !== "arbitrum" &&
        chainId !== currentChainId &&
        chainId !== chain?.name?.toLowerCase();
    })
    .map(([id, data]) => ({
      id,
      name: data.label,
      icon: data.icon
    }));

  const handleBridgeAccess = () => {
    if (selectedChain) {
      console.log(`Bridging access to ${selectedChain}`);
    }
  };

  return (
    <Card className="bridge-card" style={{ maxWidth: '48rem' }}>
      <div style={{ marginTop: -16 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <p style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.45)' }}>
            Bridge your project's access to another blockchain network. This allows your project to operate on multiple chains.
          </p>
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <Select
              style={{ width: 200 }}
              placeholder="Select a chain"
              onChange={(value) => setSelectedChain(value)}
              options={availableChains.map((chain) => ({
                value: chain.id,
                label: (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <img
                      src={chain.icon}
                      alt={chain.name}
                      style={{ width: 20, height: 20, objectFit: 'contain' }}
                    />
                    <span>{chain.name}</span>
                  </div>
                )
              }))}
            />
            <Button
              type="primary"
              icon={<SwapOutlined />}
              onClick={handleBridgeAccess}
              disabled={!selectedChain}
            >
              Bridge Access
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}