import React, {useEffect} from "react";
import { useAppKit, useAppKitAccount, useAppKitState } from '@reown/appkit/react'
import {getLocal, setLocal} from "web3modal";
import {useNavigate} from "react-router-dom";
import {useAccountEffect} from "wagmi";
import {useAuthenticate} from "../hooks/useAuthenticate";

export default function ConnectButton(props) {

    const { open } = useAppKit()
    const { isConnected, isReconnecting } = useAppKitAccount()
    const { open: stateOpen } = useAppKitState()
    const { authenticate } = useAuthenticate();

    const navigate = useNavigate();
    function truncateText(text, length) {
        return text.length <= length ? text : text.slice(0, 5) + "..." + text.slice(-4);
    }
    useEffect(() => {
        document.documentElement.style.setProperty(
            '--scrollBar',
            stateOpen ? 'stable' : 'auto'
          );
      }, [stateOpen]);

    const handleOpenWeb3Modal = () => {
        if(isConnected){
            if (props.buttonType === "web3Button"){
                open({ view: 'Account' })
            } else {
                props.homePageNavigation ? navigate("/registration") : navigate("/cabinet")
            }
        } else {
            setLocal("homePageNavigation", props.homePageNavigation);
            open({ view: 'Connect' })
        }
    }

    window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
            const newAccount = accounts[0];
            const savedAccount = localStorage.getItem("connectedAccount");

            if (savedAccount !== newAccount) {
                localStorage.removeItem("authToken")
                localStorage.removeItem("expirationDate")
                localStorage.setItem("connectedAccount", newAccount);
                if (getLocal("homePageNavigation") === true){
                    navigate('/registration')
                } else {
                    navigate('/cabinet');
                }
            }
        } else {
            localStorage.removeItem("authToken")
            localStorage.removeItem("expirationDate")
            localStorage.removeItem("connectedAccount");
        }
    });

    useAccountEffect({
        onDisconnect() {
            localStorage.removeItem('authToken');
            localStorage.removeItem('expirationDate');
            localStorage.removeItem('homePageNavigation');
            console.log('Disconnected')
            navigate('/');
        },
    })

    return (
        props.buttonType === "web3Button" ?
            <button className="connect" onClick={handleOpenWeb3Modal}>{props?.buttonName
                ? truncateText(props.buttonName, 8) : "Connect"}</button>
            :
            <button className={props.buttonType} onClick={handleOpenWeb3Modal}>{props.buttonName}</button>
    )
}
