import React from 'react';
import "../../assets/scss/_home.scss"
import distribution from "../../assets/images/home/distribution.svg";


export default function Distribution() {
    const data = [
        { title: "13% Public Sale", description: "35% TGE, 0 mo cliff, 6 mo vesting", accent: "#CAB2D6" },
        { title: "3% Private Sale", description: "0% TGE, 1 week cliff, 25% after cliff 12 mo vesting", accent: "#6A3D9A" },
        { title: "10% Exchange Liquidity", description: "25% TGE, 3 months vesting", accent: "#A6CEE3" },
        { title: "5% Airdrops", description: "36 mo linear vesting", accent: "#1F78B4" },
        { title: "12% Project Fund", description: "48 mo distribution, 3 mo lock-up", accent: "#B2DF8A" },
        { title: "12% Curators Program", description: "48 mo distribution, 3 mo lock-up", accent: "#33A02C" },
        { title: "10% Partnerships", description: "12 mo cliff, 36 mo vesting", accent: "#FB9A99" },
        { title: "7% Marketing", description: "24 mo vesting, 3 mo lock-up", accent: "#D9D9D9" },
        { title: "5% Advisors", description: "18 mo cliff, 24 mo vesting", accent: "#FDBF6F" },
        { title: "10% Team", description: "18 mo cliff, 24 mo vesting", accent: "#B8B8B8" },
        { title: "13% Reserves", description: "1% TGE, 60 mo Vesting", accent: "#FFFF99" },
    ];

    return (
        <div className="distribution">
            <div className="-left">
                <img className="-img" src={distribution} alt=""/>
                <div className="-table">
                        <div className="-cell header">Info</div>
                        <div className="-cell header">Value</div>
                        <div className="-cell">Token Ticker</div>
                        <div className="-cell">FORC</div>
                        <div className="-cell accent">Total Supply</div>
                        <div className="-cell accent">1,000,000,000 FORC</div>
                        <div className="-cell">Blockchain</div>
                        <div className="-cell">Ethereum (ERC-20)</div>
                        <div className="-cell accent">Decimals</div>
                        <div className="-cell accent">18</div>
                </div>
            </div>
            <div className="-cards">
                {data.map((item, index) => (
                    <div key={index} className="-card">
                        <div className="-accent" style={{ background: item.accent }}></div>
                        <div>
                            <div className="-title">{item.title}</div>
                            <div className="-text">{item.description}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}