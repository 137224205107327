import React, { useState } from 'react';
import { Card, Input, Button, Typography, Divider, Space, Statistic } from 'antd';
import { DollarCircleOutlined, SwapOutlined, TrophyOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const ForcToken = ({
  forcReward = 0,
  currentRewardPerToken = 0,
  myForcStaked = 0,
  totalForcStaked = 0,
  totalForcLocked = 0,
  handleStake,
  handleUnstake,
  handleClaim
}) => {
  const [stakeAmount, setStakeAmount] = useState('');
  const [unstakeAmount, setUnstakeAmount] = useState('');

  const StatItem = ({ label, value }) => (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center',
      padding: '3px 0'
    }}>
      <Text type="secondary">{label}:</Text>
      <Text>{value}</Text>
    </div>
  );
  return (
      <Card 
        size="default"
        title="FORC Token Staking"
        style={{ 
          width: '100%',
          maxWidth: 480,
          boxShadow: '0 4px 12px rgba(0,0,0,0.08)'
        }}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          {/* Stake Input */}
          <div>
            <Text strong>Stake Amount</Text>
            <Space.Compact style={{ width: '100%', marginTop: 8 }}>
              <Input 
                placeholder="Enter stake amount"
                value={stakeAmount}
                onChange={(e) => setStakeAmount(e.target.value)}
                type="number"
                min="0"
              />
              <Button type="primary" onClick={() => handleStake(stakeAmount)}>
                Stake
              </Button>
            </Space.Compact>
          </div>
          {/* Unstake Input */}
          <div>
            <Text strong>Unstake Amount</Text>
            <Space.Compact style={{ width: '100%', marginTop: 8 }}>
              <Input 
                placeholder="Enter unstake amount"
                value={unstakeAmount}
                onChange={(e) => setUnstakeAmount(e.target.value)}
                type="number"
                min="0"
              />
              <Button type="primary" onClick={() => handleUnstake(unstakeAmount)}>
                Unstake
              </Button>
            </Space.Compact>
          </div>

          {/* Stats */}
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <StatItem label="Current reward" value={forcReward} />
            <StatItem label="Current reward per token" value={currentRewardPerToken} />
            <StatItem label="My FORC staked" value={myForcStaked} />
            <StatItem label="Total FORC staked" value={totalForcStaked} />
            <StatItem label="Total FORC locked" value={totalForcLocked} />
         </Space>


          {/* Claim Button */}
          <Button 
            type="primary"
            block
            icon={<TrophyOutlined />}
            onClick={handleClaim}
          >
            Claim
          </Button>
        </Space>
      </Card>
  );
};

export default ForcToken;