import React from "react";
import { Modal, Button } from "antd";
import { ExclamationCircleFilled } from '@ant-design/icons';

const WarningModal = ({ show, title, text, action, onClose }) => {
  return (
    <Modal
      open={show}
      title={
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          gap: '12px',
          paddingTop: '12px'
        }}>
          <ExclamationCircleFilled style={{ 
            color: '#faad14', 
            fontSize: '48px'
          }} />
          <span style={{
            fontSize: '20px',
            fontWeight: 500
          }}>{title}</span>
        </div>
      }
      closeIcon={null}
      onCancel={onClose}
      maskClosable={true}
      footer={
        <div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
          <Button key="cancel" onClick={onClose}>
            No
          </Button>
          <Button 
            key="confirm" 
            type="primary" 
            danger 
            onClick={action}
          >
            Yes
          </Button>
        </div>
      }
      centered
      width={420}
    >
      <div style={{ padding: '2px 0' }}>
        <p style={{ 
          margin: 0, 
          fontSize: '14px', 
          textAlign: 'center',
          color: 'rgba(0, 0, 0, 0.65)'
        }}>{text}</p>
      </div>
    </Modal>
  );
};

export default WarningModal;