import { http, createConfig } from 'wagmi';
import {mainnet, sepolia} from 'wagmi/chains';
import { coinbaseWallet, walletConnect } from 'wagmi/connectors';
import {
    arbitrum,
    arbitrumSepolia,
    base,
    bsc,
    linea,
    lineaTestnet,
    optimism,
    polygon,
    zkSync,
    sonic
} from "viem/chains";

let chains;
if (process.env.REACT_APP_ENV === 'DEV'){
    chains = [sepolia, arbitrumSepolia, lineaTestnet]
} else {
    chains = [mainnet, arbitrum, optimism, zkSync, polygon, bsc, linea, base, sonic]
}

export const config = createConfig({
    chains: chains,
    connectors: [
        coinbaseWallet(),
        walletConnect({ projectId: "64cde9ab3c4a61bfc8b97b59e9b69225" }),
    ],
    transports: {
        [mainnet.id]: http(),
        [arbitrum.id]: http(),
        [optimism.id]: http(),
        [zkSync.id]: http(),
        [polygon.id]: http(),
        [bsc.id]: http(),
        [linea.id]: http(),
        [base.id]: http(),
        [sonic.id]: http(),
        [sepolia.id]: http(),
        [arbitrumSepolia.id]: http(),
        [lineaTestnet.id]: http(),
    },
});
