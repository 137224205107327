import { useState } from 'react';
import '../../assets/scss/_create-token.scss';
import { Button, Input, message, Segmented, Typography } from 'antd';
import { 
    ArrowRightOutlined, 
    FireOutlined, 
    PauseCircleOutlined, 
    PlayCircleOutlined,
    CopyFilled,
    CheckCircleFilled 
} from '@ant-design/icons';
import {
    burn,
    getTotalSupply, 
    isPaused,
    mint,
    pause,
    renounceOwnership,
    transferOwnership,
    unpause,
} from "../../ethers/ERC20Contract";
import Web3 from "web3";
import { z } from "zod";
import { isAddress } from 'ethers';
import WarningModal from '../modals/WarningModal';

const { Text } = Typography;

// Define Zod validation schemas
const mintSchema = z.number().positive({ message: "Amount must be a positive number" });
const burnSchema = z.number().positive({ message: "Amount must be a positive number" });

export default function TokenManagement({ paused, ticker, tokenAddress, tokenName, totalSupply, mintable, burnable, pausable}) {
    const [newOwnerAddress, setNewOwnerAddress] = useState('');
    const [tokenPaused, setTokenPaused] = useState(paused);
    const [supply, setSupply] = useState(totalSupply);
    const [mintAmount, setMintAmount] = useState('');
    const [burnAmount, setBurnAmount] = useState('');
    const [selectedSegment, setSelectedSegment] = useState(mintable ? 'Mint' : "Ownership");
    const [warningModal, setWarningModal] = useState(false);
    const [copied, setCopied] = useState(false);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(tokenAddress);
            setCopied(true);
            message.success('Copied to clipboard!');
            
            // Reset the copied state after 2 seconds
            setTimeout(() => {
                setCopied(false);
            }, 5000);
        } catch (err) {
            message.error('Failed to copy!');
        }
    };
    const handleMint = async () => {
        const parsedAmount = mintSchema.safeParse(Number(mintAmount));
        if (!parsedAmount.success) {
            message.error(parsedAmount.error.errors[0].message);
            return;
        }
        await mint(tokenAddress, Web3.utils.toWei(mintAmount, "ether"));
        message.success("Tokens minted successfully!");
        const res = Web3.utils.fromWei((await getTotalSupply(tokenAddress)).toString(), "ether")
        setSupply(res)
    };

    const handleBurn = async () => {
        const parsedAmount = burnSchema.safeParse(Number(burnAmount));
        if (!parsedAmount.success) {
            message.error(parsedAmount.error.errors[0].message);
            return;
        }
        await burn(tokenAddress, Web3.utils.toWei(burnAmount, "ether"));
        message.success("Tokens burned successfully!");
        const res = Web3.utils.fromWei((await getTotalSupply(tokenAddress)).toString(), "ether")
        setSupply(res)
    };

    const handleRenounce = async () => {
        setWarningModal(true)
    };
    const Renounce = async () => {
        await renounceOwnership(tokenAddress);
        message.success("Ownership renounced successfully!");
    };
    

    const handleTransfer = async () => {
        const parsedAddress = isAddress(newOwnerAddress);
        if (!parsedAddress) {
            message.error("Enter correct address");
            return;
        }
        await transferOwnership(tokenAddress, newOwnerAddress);
        message.success("Ownership transferred successfully!");
    };

    const handlePause = async () => {
        await pause(tokenAddress);
        message.success("Token paused successfully!");
        const paused = await isPaused(tokenAddress)
        setTokenPaused(paused)
    };
    const handleUnpause = async () => {
        await unpause(tokenAddress);
        message.success("Token unpaused successfully!");
        const paused = await isPaused(tokenAddress)
        setTokenPaused(paused)
    };


    return (
        <>
            <WarningModal 
                show={warningModal}  
                onClose={() => setWarningModal(false)}
                title="Warning"
                text="Are you sure you want to proceed with this action?"
                action={() => Renounce()}
            />
            <div className="token-management">
                <div className="-title center">{tokenName} tokens</div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4px' }}>
                    <div>
                        <div className="-text">Ticker</div>
                        <div className="-text l">{ticker}</div>
                    </div>
                    <div>
                        <div className="-text">Total Supply</div>
                        <div className="-text l">{supply}</div>
                    </div>
                </div>
                <div style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    background: '#f0f0f0', 
                    padding: '8px', 
                    borderRadius: '4px',
                    gap: '8px',
                    justifyContent: 'space-between'
                }}>
                    <Text style={{ 
                        fontSize: '11.5px',
                        margin: 0,
                        flex: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {tokenAddress}
                    </Text>
                    {copied ? (
                        <CheckCircleFilled 
                            style={{ 
                                fontSize: '14px', 
                                color: '#52c41a',
                                cursor: 'pointer'
                            }} 
                        />
                    ) : (
                        <CopyFilled 
                            onClick={handleCopy}
                            style={{ 
                                fontSize: '14px', 
                                color: '#777777',
                                cursor: 'pointer'
                            }} 
                        />
                    )}
                </div>
                <Segmented
                    options={[
                        {
                            label: 'Mint',
                            value: 'Mint',
                            disabled: !mintable,
                        },
                        {
                            label: 'Burn',
                            value: 'Burn',
                            disabled: !burnable,
                        },
                        {
                            label: 'Pause',
                            value: 'Pause',
                            disabled: !pausable,
                        },
                        {
                            label: 'Ownership',
                            value: 'Ownership',
                            disabled: false,
                        },
                    ]}
                    value={selectedSegment}
                    onChange={setSelectedSegment}
                    // size={"small"}
                    style={{ margin: '4px 0', fontSize: '12px' }}
                    block
                />

                {selectedSegment === 'Mint' && (
                    <div>
                        <Input
                            placeholder="Amount to mint"
                            value={mintAmount}
                            onChange={(e) => setMintAmount(e.target.value)}
                            style={{ marginBottom: '16px' }}
                            type="number"
                        />
                        <Button type="primary" onClick={handleMint} style={{ width: '100%' }}>
                            Mint Tokens
                        </Button>
                    </div>
                )}

                {selectedSegment === 'Burn' && (
                    <div>
                        <Input
                            placeholder="Amount to burn"
                            value={burnAmount}
                            onChange={(e) => setBurnAmount(e.target.value)}
                            style={{ marginBottom: '16px' }}
                            type="number"
                        />
                        <Button type="primary" danger onClick={handleBurn} style={{ width: '100%' }}>
                            Burn Tokens <FireOutlined style={{ marginLeft: '8px' }} />
                        </Button>
                    </div>
                )}

                {selectedSegment === 'Ownership' && (
                    <div>
                        <Input
                            placeholder="New owner address"
                            value={newOwnerAddress}
                            onChange={(e) => setNewOwnerAddress(e.target.value)}
                            style={{ marginBottom: '16px' }}
                        />
                        <Button type="primary" onClick={handleTransfer} style={{ width: '100%' }}>
                            Transfer Ownership <ArrowRightOutlined style={{ marginLeft: '8px' }} />
                        </Button>
                        <Button onClick={handleRenounce} style={{ width: '100%', marginTop: '10px' }}>
                            Renounce Ownership
                        </Button>
                    </div>
                )}
                {selectedSegment === 'Pause' && (
                    tokenPaused ?
                    <Button onClick={handleUnpause} style={{ width: '100%' }}>
                        <PlayCircleOutlined style={{ marginRight: '8px' }} /> Unpause
                    </Button>
                        :
                    <Button onClick={handlePause} style={{ width: '100%' }}>
                        <PauseCircleOutlined style={{ marginRight: '8px' }} /> Pause
                    </Button>
                )}
            </div>
        </>
    );
}
