import { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { getNonce } from "../controllers/ServerController";
import * as ethers from "ethers";

export const useAuthenticate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const EXPIRATION_DATE_TOKEN_IN_SECONDS = 2592000000;

    const [authStatus, setAuthStatus] = useState(null); // State to track authentication status

    const authenticate = async (address) => {
        try {
            const response = await getNonce(address);
            await signMessageEthers(response.data.message, response.data.tempToken);
        } catch (error) {
            console.error("Error during authentication", error);
            setAuthStatus("error"); // Set error status
        }
    };

    let provider;

    if (window.ethereum) {
        provider = new ethers.BrowserProvider(window.ethereum);
    } else {
        console.error("No browser or Ethereum provider available.");
    }

    const signMessageEthers = async (signatureMessage, signatureToken) => {
        try {
            const signer = await provider.getSigner();
            const signature = await signer.signMessage(signatureMessage);
            await authenticateOnServer(signature, signatureToken);
        } catch (error) {
            console.error("Error signing message", error);
            setAuthStatus("error"); // Set error status
        }
    };

    const authenticateOnServer = async (signature, signatureToken) => {
        try {
            const opts = {
                method: 'POST',
                body: JSON.stringify({ "referredBy": "" }),
                headers: {
                    'Content-Type': "application/json",
                    "Authorization": `Bearer ${signatureToken}`
                }
            };
            const res = await fetch(`/verify?signature=${signature}`, opts);
            const resBody = await res.json();

            localStorage.setItem('authToken', "Bearer " + resBody.token);
            const expirationDate = Date.now() + EXPIRATION_DATE_TOKEN_IN_SECONDS;
            localStorage.setItem('expirationDate', expirationDate.toString());

            setAuthStatus("success"); // Set success status

            if (location.pathname === "/curators") {
                navigate('/curators');
            }
        } catch (error) {
            console.error("Error authenticating on server", error);
            setAuthStatus("error"); // Set error status
        }
    };

    return {
        authenticate,
        authStatus, // Expose authentication status
    };
};
