import React from 'react';
import {Tag} from "antd";
import {useAccount, useSwitchChain} from "wagmi";

function NetworkSelect({network, setNetwork}) {
    const { chain } = useAccount()
    const { chains, switchNetwork } =
        useSwitchChain()

    const handleChangeNetwork = networkName => {
        switchNetwork?.(networkName);
    };
    return (
        <div>{
                chains.map((tag) => (
                    <Tag
                        style={{margin: '0 5px', cursor: 'pointer'}}
                        color={chain?.id === tag.id ? 'geekblue' : ''}
                        onClick={() => handleChangeNetwork(tag.id)}
                    >
                        {tag.name}
                    </Tag>
                ))}
        </div>
    );
}

export default NetworkSelect;
