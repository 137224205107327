import React, {useEffect, useState} from 'react';
import "../assets/scss/_cabinet.scss";
import "../assets/scss/_components.scss";
import "../assets/scss/_layout.scss"
import {Tabs, Button, Flex} from 'antd';
import {useAccount} from 'wagmi';
import {getUserAvatar, getUserProfile} from "../controllers/UserProfileController";
import NotificationsMessage from "../components/NotificationsMessage";
import UserSettings from "../components/user/UserSettings";
import CompanyCard from "../components/project/CompanyCard";
import Skills from "../components/user/Skills";
import CreateSpaceIdModal from "../components/modals/CreateSpaceIdModal";
import Avatar from "../components/user/Avatar";
import Description from "../components/user/Description";
import {getReceivedNotifications} from "../controllers/NotificationController";
import {getDfoCompaniesByOwner, getDfoCompanyById} from "../controllers/ProjectsController";
import MyTokens from "../components/user/MyTokens";
import { Skeleton } from 'antd';
import {useAuthenticate} from "../hooks/useAuthenticate";
import {shouldAuthenticate} from "../utils/shouldAuthenticate";

export default function Cabinet() {
    const [isLoading, setIsLoading] = useState(true);
    const [spaceIdModal, setSpaceIdModal] = useState(false);
    const { TabPane } = Tabs;
    const { address, chain } = useAccount();
    const { authenticate, authStatus } = useAuthenticate();

    const [userData, setUserData] = useState({});
    const [userLogo, setUserLogo] = useState("https://media.istockphoto.com/id/1131164548/vector/avatar-5.jpg?s=612x612&w=0&k=20&c=CK49ShLJwDxE4kiroCR42kimTuuhvuo2FH5y_6aSgEo=");

    const [userRoles, setUserRoles] = useState();

    const [allowWhitelist, setAllowWhitelist] = useState(false);
    const [myOwnedCompanies, setMyOwnedCompanies] = useState([]);
    const [companiesIFollow, setCompaniesIFollow] = useState([]);

    const [editDataModal, setEditDataModal] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const getDfos = async() => {
            setMyOwnedCompanies(await getDfoCompanyData(address));
        };

        getDfos();
        getCompaniesIFollow();
        geActiveUserProfile();
        setIsAuthenticated(shouldAuthenticate)
        const addresses = process.env.REACT_APP_TESTNET_WHITELISTER_ADDRESS.split(',');
        for (const addressFromEnv of addresses){
            if (address === addressFromEnv){
                setAllowWhitelist(true);
            }
        }
    // eslint-disable-next-line
    }, [address, authStatus])

    useEffect(() => {
        const getNotifications = async () => {
            try {
                const notificationsData = await getReceivedNotifications(address);
                const notificationsFromDb = notificationsData.map((notification) => (
                    <NotificationsMessage key={notification.id} notification={notification} />
                ));
                setNotifications(notificationsFromDb);
            } catch (error) {
                console.error('Error in fetching notifications:', error);
            }
        };
        getNotifications()
    }, [address])

    const getCompaniesIFollow = async (walletAddresses) => {
        const companiesList = [];
        try {
            for (const walletAddress of walletAddresses) {
                const dfoData = await getDfoCompanyById(walletAddress);
                if (dfoData !== null) {
                    companiesList.push(<CompanyCard key={dfoData.id} item={dfoData} />);
                }
            }
            setCompaniesIFollow(companiesList);
        } catch (error) {
            console.error('Error fetching companies I follow:', error);
        }
    };

    const getDfoCompanyData = async () => {
        try {
            const dfos = await getDfoCompaniesByOwner(address);
            return dfos
                .filter((dfo) => dfo !== null)
                .map((dfo) => <CompanyCard key={dfo.id} item={dfo}/>);
        } catch (error) {
            console.error('Error fetching my DFO companies:', error);
        }
    };

    const geActiveUserProfile = async () => {
        try {
            const response = await getUserProfile(address);

            // On successful response, set user data
            setUserData({
                nickName: response.data.nickName,
                walletId: address,
                facebook: response.data.facebook,
                discord: response.data.discord,
                telegram: response.data.telegram,
                twitter: response.data.twitter,
                youtube: response.data.youtube,
                linkedin: response.data.linkedin,
                aboutme: response.data.aboutme,
                email: response.data.email,
                userRole: response.data.userRole,
                refLink: response.data.refLink,
                preferredSocialMedia: response.data.preferredSocialMedia,
            });

            setUserRoles({
                isFundraisingAdvisory: response.data.isFundraisingAdvisory,
                isStrategicAdvisory: response.data.isStrategicAdvisory,
                isLegalAdvisory: response.data.isLegalAdvisory,
                isTechnicalAdvisory: response.data.isTechnicalAdvisory,
                isFinancialAdvisory: response.data.isFinancialAdvisory,
                isProductAdvisory: response.data.isProductAdvisory,
                isMarketingAdvisory: response.data.isMarketingAdvisory,
                isTokenomicsAdvisory: response.data.isTokenomicsAdvisory,
                isGotoMarketStrategy: response.data.isGotoMarketStrategy,
                isGrowthStrategy: response.data.isGrowthStrategy,
                isListings: response.data.isListings,
                isLiquidity: response.data.isLiquidity,
                isInfrastructure: response.data.isInfrastructure,
                isSmartContracts: response.data.isSmartContracts,
                isNFT: response.data.isNFT,
                isMetaverse: response.data.isMetaverse,
                isGameFi: response.data.isGameFi,
                isMarketingExecution: response.data.isMarketingExecution,
                isPR: response.data.isPR,
                isContentCreation: response.data.isContentCreation,
                isTwitterKOL: response.data.isTwitterKOL,
                isYoutubeKOL: response.data.isYoutubeKOL,
                isInfluencer: response.data.isInfluencer,
                isAmbassador: response.data.isAmbassador,
                isCommunityBuilding: response.data.isCommunityBuilding,
                isCommunityManagement: response.data.isCommunityManagement,
                isDesign: response.data.isDesign,
                isUIUX: response.data.isUIUX,
                isBusinessModel: response.data.isBusinessModel,
                isPitchDeck: response.data.isPitchDeck,
                isWhitepaper: response.data.isWhitepaper,
                isHrRecruiting: response.data.isHrRecruiting,
                isDefi: response.data.isDefi,
                isAi: response.data.isAi,
                isTokenDesign: response.data.isTokenDesign,
                isMarketMaker: response.data.isMarketMaker,
            });

            // Fetch companies the user follows
            await getCompaniesIFollow(response.data.followCompanies);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setUserData({
                    walletId: address
                });
                console.warn("User profile not found (404). Setting fallback data.");
            } else {
                console.error("Error fetching user profile:", error);
            }
        } finally {
            // Fetch the user avatar and set loading to false
            try {
                const data = await getUserAvatar(address);
                if (data.avatarLogo !== undefined) {
                    setUserLogo(data.avatarLogo);
                }
            } catch (avatarError) {
                console.error("Error fetching user avatar:", avatarError);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleCloseEditModal = () => {
        setEditDataModal(false);
    }

    return (
        <>
            <div className="back home"/>
            <div className="main-container">
                <div className='user-data'>
                    <div className='-container'>
                    {isLoading ?
                        <div className='-user-info-block'>
                            <Skeleton.Avatar active size={200} shape="square" className='-image' />
                            <Skeleton active paragraph={{ rows: 4 }} style={{ width: '500px' }} />
                        </div>
                         :
                        <div className='-user-info-block'>
                            <div>
                                <Avatar name={userData.nickName} img={userLogo} socials={userData} disableName={true}/>
                            </div>
                            <div>
                                <div className='-nickName xl bold'>{userData.nickName}</div>
                                {/*{userData?.walletId &&*/}
                                <Description title="Wallet address" text={address}/>
                                {/*}*/}
                                {userData?.aboutme  &&
                                   <Description title="Description" text={userData.aboutme}/>
                                }
                                {userRoles && <Skills roles={userRoles} />}
                            </div>
                        </div>
                        }
                        <div className='-buttons'>
                            {chain && chain.network === "10243" &&
                                <a href={"https://faucet.arthera.net/"} target={"_blank"} rel="noreferrer">
                                    <Button>Arthera faucet</Button>
                                </a>
                            }

                        </div>
                        <div className='-dfo-container'>
                            <Tabs>
                                {/*<TabPane tab="My Tokens" key="tokens">*/}
                                {/*    <MyTokens />*/}
                                {/*</TabPane>*/}
                                {myOwnedCompanies.length > 0 &&
                                    <TabPane tab="My Projects" key="myProjects">
                                        <div className='-company-wrapper'>
                                            {myOwnedCompanies}
                                        </div>
                                    </TabPane>
                                }
                                <TabPane tab="Followed Projects" key="followedProjects">
                                    <div className='-company-wrapper'>
                                        {companiesIFollow}
                                    </div>
                                    {companiesIFollow.length === 0 && <div>There are no projects you have followed by now</div>}
                                </TabPane>
                                <TabPane tab="Notifications" key="notifications">
                                    <div className='-links-wrapper'>
                                        {notifications}
                                    </div>
                                    {notifications.length === 0 && <div>There are no notifications</div>}
                                </TabPane>
                                <TabPane tab="Settings" key="editData">
                                    {isAuthenticated ?
                                        <Flex justify='center'>
                                            <Button style={{marginTop: '8px'}} htmlType="submit" onClick={() => authenticate(address)}>Sign message to access settings</Button>
                                        </Flex>
                                        :
                                        <UserSettings
                                            show={editDataModal}
                                            userData={userData}
                                            isAdmin={false}
                                            close={handleCloseEditModal}
                                            updateAvatar={geActiveUserProfile}
                                            userRoles={userRoles}
                                        />
                                    }
                                </TabPane>
                                { allowWhitelist &&
                                    <TabPane tab="Admin" key="admin">
                                        <UserSettings userData={{}} userRoles={{}} isAdmin={true} />
                                    </TabPane>
                                }

                            </Tabs>
                        </div>
                    </div>
                </div>
                <CreateSpaceIdModal show={spaceIdModal} close={() => setSpaceIdModal(false)} mainWalletAddress={userData.walletId} companyName={userData.nickName} isCompany={false}/>
            </div>
        </>
    );
}
