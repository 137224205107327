import React, {useCallback, useEffect, useState} from 'react';
import "../assets/scss/_dfo-page.scss"
import "../assets/scss/_components.scss"
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {Button, Card, Flex, Switch, Tag} from 'antd';

import Discord from "../assets/icons/curators/discord.svg";
import Facebook from "../assets/icons/curators/facebook.svg";
import Youtube from "../assets/icons/curators/youtube.svg";
import Twitter from "../assets/icons/curators/twitter.svg";
import Telegram from "../assets/icons/curators/telegram.svg";
import Linkedin from "../assets/icons/curators/linkedin.svg";
import CreateSpaceIdModal from "../components/modals/CreateSpaceIdModal";
import ProjectSettings from "../components/project/ProjectSettings";
import SaleTable from "../components/token/table/SaleTable";
import VestingTable from "../components/token/table/VestingTable";
import {addFollowCompany, getUserProfile, removeFollowCompany} from "../controllers/UserProfileController";
import {getDfoCompanyById} from "../controllers/ProjectsController";
import {getProjectTokens} from "../ethers/ContractFactory";
import {getTokenName, getTokenTicker, getTotalSupply, isPaused} from "../ethers/ERC20Contract";
import Web3 from "web3";
import TokenManagement from "../components/token/TokenManagement";
import Packages from "../components/project/Packages";
import {bridgeToken, hasCreationToken} from "../ethers/ForcefiPackage";
import BridgingAccess from '../components/project/BridgingAccess';
import {useAccount} from 'wagmi';
import {useAuthenticate} from "../hooks/useAuthenticate";
import {shouldAuthenticate} from "../utils/shouldAuthenticate";

const { ethers } = require('ethers');

export default function DfoPage() {

    const { address } = useAccount();
    const { authenticate, authStatus } = useAuthenticate();

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const [imgUrl, setImgUrl] = useState('https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg');
    const [backgroundImage, setBackgroundImage] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    const [mainWalletAddress, setMainWalletAddress] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [tokens, setTokens] = useState([]);

    const [about, setAbout] = useState('');
    const [documentation, setDocumentation] = useState('');
    const [elevatorPitch, setElevatorPitch] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [adminAddress, setAdminAddress] = useState('');

    const [twitter, setTwitter] = useState('');
    const [discord, setDiscord] = useState('');
    const [youtube, setYoutube] = useState('');
    const [facebook, setFacebook] = useState('');
    const [telegram, setTelegram] = useState('');
    const [linkedin, setLinkedIn] = useState('');

    const [tab, setTab] = useState("editProject");
    const [spaceIdModal, setSpaceIdModal] = useState(false);

    const[isAdmin, setIsAdmin] = useState(false);
    const[projectStatus, setProjectStatus] = useState(false);
    const[listingTier, setListingTier] = useState("");

    const[showFollowButton, setShowFollowButton] = useState(true);
    const[disableFollowButton, setDisableFollowButton] = useState(false);

    const[isFollowAvialable, setIsFollowAvialable] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const getDfoCompanyData = useCallback(async () => {
        const params = new URLSearchParams(location.search)

        try {
            const data = await getDfoCompanyById(params.get("id"), params.get("chain"));

            if (data === null) {
                navigate("/401");
                return;
            }

            setImgUrl(data.logo);
            setBackgroundImage(data.backgroundImage);
            setAbout(data.about);
            setDocumentation(data.documentation);
            setElevatorPitch(data.elevatorPitch);
            setTwitter(data.twitter);
            setDiscord(data.discord);
            setYoutube(data.youtube);
            setFacebook(data.facebook);
            setTelegram(data.telegram);
            setLinkedIn(data.linkedin);
            setWebsiteUrl(data.websiteUrl);
            setCompanyName(data.companyName);
            setAdminAddress(ethers.getAddress(data.owner));
            setIsAdmin(ethers.getAddress(data.owner) === ethers.getAddress(address));
            setProjectStatus(data.projectStatus);
            setListingTier(data.listingTier);
            setMainWalletAddress(data.mainWalletAddress);
        } catch (error) {
            console.error("Failed to fetch DFO company data:", error);
        }
    }, [apiUrl, address, location.search, navigate]);

    useEffect(() => {
        getDfoCompanyData();
        const handleGetProjectTokens = async () => {
            const erc20Addresses = await getProjectTokens(companyName);
            const dataFromBlockchain = [];
            for (const erc20Token of erc20Addresses ){
                const tokenAddress = erc20Token.address
                dataFromBlockchain.push({
                    index: 1,
                    ticker: await getTokenTicker(tokenAddress),
                    totalSupply: Web3.utils.fromWei((await getTotalSupply(tokenAddress)).toString(), "ether"),
                    tokenName: await getTokenName(tokenAddress),
                    tokenAddress: tokenAddress,
                    paused: await isPaused(tokenAddress),
                    burnable: erc20Token.burnable,
                    mintable: erc20Token.mintable,
                    pausable: erc20Token.pausable
                })
            }
            setTokens(dataFromBlockchain)
        }
        const getAccountData = async () => {
            try {
                const userProfile = await getUserProfile(address);
                if (userProfile.data.followCompanies.map(v => v.toLowerCase()).includes(companyName.toLowerCase())) {
                    setShowFollowButton(false);
                }
                if (userProfile) {
                    setIsFollowAvialable(true);
                }
            } catch (error) {
                console.error("Failed to get account data:", error);
            }
        };
        if (address){
            getAccountData();
        }
        handleGetProjectTokens()
        checkCreationToken()
        setIsAuthenticated(shouldAuthenticate)
    },[mainWalletAddress, showFollowButton, address, getDfoCompanyData, companyName, authStatus])

    const followCompany = async () => {
        try {
            setDisableFollowButton(true);
            await addFollowCompany(apiUrl, companyName, address);
            setShowFollowButton(false);
        } catch (error) {
            // Handle the error (optional)
            console.error("Failed to follow company:", error);
        } finally {
            setDisableFollowButton(false);
        }
    };

    const handleRemoveFollowCompany = async () => {
        try {
            setDisableFollowButton(true);
            await removeFollowCompany(apiUrl, companyName, address);
            setShowFollowButton(true);
        } catch (error) {
            // Handle the error (optional)
            console.error("Failed to unfollow company:", error);
        } finally {
            setDisableFollowButton(false);
        }
    };

    const handleBridgeToken = async (destChainNetworkId) => {
        await bridgeToken(destChainNetworkId, companyName)
    }

    const checkCreationToken = async () => {
        await hasCreationToken(companyName)
    }

    const SocialLink = ({ href, imgSrc, altText }) => {
        if (!href || !imgSrc) return null;
        return (
            <a href={href} target="_blank" rel="noreferrer">
                <img className="-item" alt={altText || "Social icon"} src={imgSrc} />
            </a>
        );
    };

    return (
        <>
            <CreateSpaceIdModal show={spaceIdModal} close={() => setSpaceIdModal(false)} mainWalletAddress={mainWalletAddress} companyName={companyName}/>

            { backgroundImage
                ?   <div className='back' style={{ backgroundImage: `url(${backgroundImage})` }} />
                : <div className='back' />
            }
            <div className="main-container">
                <div className='dfo-data first'>
                    <div className='-container'>
                        {isFollowAvialable &&
                            <div  className='-switch'>
                                Follow
                                <Switch checked={!showFollowButton} loading={disableFollowButton} onChange={showFollowButton ? ()=>followCompany() : () => handleRemoveFollowCompany()}/>
                            </div>
                        }
                        <div className='-main-info'>
                            <div className='-left'>
                                <img className='-logo' alt='' src={imgUrl}/>
                            </div>
                            <div className='-dfo-data'>
                                <div className='-text xl'>{companyName}</div>
                                <div className="-socials">
                                    <SocialLink href={linkedin} imgSrc={Linkedin} altText="LinkedIn" />
                                    <SocialLink href={twitter} imgSrc={Twitter} altText="Twitter" />
                                    <SocialLink href={telegram} imgSrc={Telegram} altText="Telegram" />
                                    <SocialLink href={discord} imgSrc={Discord} altText="Discord" />
                                    <SocialLink href={youtube} imgSrc={Youtube} altText="YouTube" />
                                    <SocialLink href={facebook} imgSrc={Facebook} altText="Facebook" />
                                </div>
                                <div className="-tags">
                                    {listingTier && <Tag color="blue" >{listingTier}</Tag>}
                                    { projectStatus && <Tag color="geekblue" >{projectStatus}</Tag>}
                                </div>
                                <div className="-tags">
                                    {websiteUrl !== "" && websiteUrl !== undefined &&

                                        <a href={websiteUrl} target='_blank' rel="noreferrer">
                                            <Tag
                                            >Website
                                            </Tag>
                                        </a>
                                    }
                                    {documentation !== "" && documentation !== undefined &&
                                        <a href={documentation} target='_blank' rel="noreferrer">
                                            <Tag
                                            >WhitePaper
                                            </Tag>
                                        </a>
                                    }
                                </div>
                                <div className='-text s'>
                                    {about}
                                </div>

                                {isAdmin &&
                                    <>
                                <div className='-buttons'>
                                    {/*<Button type={tab === "token" ? "primary" : "default"} onClick={() => setTab("token")}>Project management</Button>*/}
                                    <Button type={tab === "editProject" ? "primary" : "default"} onClick={() => setTab("editProject")}>Edit project</Button>
                                    {/*<Button type={tab === "bridgingAccess" ? "primary" : "default"} onClick={() => setTab("bridgingAccess")}>Bridge project to another chain</Button>*/}
                                    <a href="https://calendly.com/mskoblovs/meetings?month=2023-06" target='_blank' rel="noreferrer">
                                        <Button>Book a free call with Forcefi</Button>
                                    </a>
                                </div>
                            </>
                            }
                            </div>

                        </div>
                        {isAdmin && tab === "editProject" &&
                                ( isAuthenticated

                                    ?
                                    <div>
                                        <Flex justify='center'>
                                            <Button style={{marginTop: '8px'}} htmlType="submit"
                                                onClick={() => authenticate(address)}>Sign message to access settings</Button>
                                        </Flex>
                                    </div>
                                    :
                                    <ProjectSettings
                                        companyName={companyName}
                                        imgUrl={imgUrl}
                                        about={about}
                                        documentation={documentation}
                                        twitter={twitter}
                                        discord={discord}
                                        youtube={youtube}
                                        facebook={facebook}
                                        telegram={telegram}
                                        linkedIn={linkedin}
                                        websiteUrl={websiteUrl}
                                        elevatorPitch={elevatorPitch}
                                        updatePrjectData={getDfoCompanyData}
                                    />

                                )
                        }
                        {isAdmin && tab === "bridgingAccess" &&
                            <BridgingAccess />
                        }
                        { isAdmin && tab === "token" && <>


                            <Card size="default" title="Launch a fundraise" extra={ isAdmin ?
                                <NavLink to="/fundraising" state={{ projectName: companyName }}>
                                    <Button type="primary">New Fundraise</Button>
                                </NavLink>
                                : <div/>
                            }>
                                <SaleTable projectName={companyName} />
                            </Card>


                            <Card size="default" title="Vesting" extra={ isAdmin ?
                                <NavLink to="/vesting" state={{ projectName: companyName }}>
                                    <Button type="primary">Create vesting</Button>
                                </NavLink> : <div/>}>
                                <VestingTable projectName={companyName} isAdmin={isAdmin}/>
                            </Card>
                            <Card size="default" title="Tokens" extra={ isAdmin ?
                                <NavLink to="/create-token" state={{ projectName: companyName }}>
                                    <Button type="primary">Create token</Button>
                                </NavLink>
                                : <div/>
                            }>
                                <div className="tokens">
                                    {tokens.map((token) => (
                                        <TokenManagement
                                            paused={token.paused}
                                            ticker={token.ticker}
                                            tokenAddress={token.tokenAddress}
                                            tokenName={token.tokenName}
                                            totalSupply={token.totalSupply}
                                            mintable={token.mintable}
                                            burnable={token.burnable}
                                            pausable={token.pausable}
                                        />
                                    ))}
                                </div>
                            </Card>
                        </>

                        }
                        { !isAdmin && <>
                            <SaleTable projectName={companyName} adminAddress={adminAddress}/>
                            <VestingTable projectName={companyName} isAdmin={isAdmin}/>
                        </>}


                    </div>
                    {listingTier !== "Accelerator" && isAdmin &&
                        <Packages
                            getDfoCompanyData={() => getDfoCompanyData()}
                            listingTier={listingTier}
                            companyName={companyName}
                        />
                    }
                    {/*<Flex justify='center' style={{ marginTop: '20px' }}>*/}
                    {/*    <Button onClick={() => handleBridgeToken(421614)}>Bridge package to Arbitrum</Button>*/}
                    {/*</Flex>*/}
                    {/*<Flex justify='center' style={{ marginTop: '20px' }}>*/}
                    {/*    <Button onClick={() => handleBridgeToken(11155111)}>Bridge package to Sepolia</Button>*/}
                    {/*</Flex>*/}
                </div>
            </div>
        </>
    );
}
