import { arbitrum, arbitrumSepolia, bsc, lineaTestnet, mainnet, optimism, polygon, zkSync, sepolia, linea, base, sonic } from '@reown/appkit/networks';
import { EthersAdapter } from '@reown/appkit-adapter-ethers';

// Get projectId from an environment variable
export const projectId = "64cde9ab3c4a61bfc8b97b59e9b69225";

if (!projectId) {
    throw new Error('Project ID is not defined');
}

// Create a metadata object - optional
export const metadata = {
    name: 'AppKit',
    description: 'AppKit Example',
    url: 'https://reown.com', // origin must match your domain & subdomain
    icons: ['https://avatars.githubusercontent.com/u/179229932']
};



let chains;
if (process.env.REACT_APP_ENV === 'DEV'){
    chains = [sepolia, arbitrumSepolia, lineaTestnet]
} else {
    chains = [mainnet, arbitrum, optimism, zkSync, polygon, bsc, linea, base, sonic]
}

// Define networks
export const networks = chains;

// Set up Ethers Adapter
export const ethersAdapter = new EthersAdapter();
