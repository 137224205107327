import * as ethers from "ethers";
import Fundraising from "../abis/FundRaising.json";
import erc20InvestmentTokens from "../constants/ERC20InvestmentTokens";
import {saveFundraising} from "../controllers/FundraisingController";
import {saveContributor} from "../controllers/ContributorController";

const handleAddFundraising = async (vestingPlanParams, investmentTokens, referralAddress, projectName, mintingToken, whitelistedAddresses) => {

    console.log("Vesting Plan params " + JSON.stringify(vestingPlanParams))
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        const saleUUIDPromise = new Promise((resolve, reject) => {
            fundraisingContract.on("FundraisingCreated", (arg1, arg2, arg3, event) => {
                console.log("Event received:", arg1, arg2, arg3);
                console.log("Event:", event);
                resolve(arg2);
            });
        });

        const tx = await fundraisingContract.createFundraising(vestingPlanParams,
            investmentTokens,
            referralAddress,
            projectName,
            mintingToken,
            whitelistedAddresses,
            { value: "0" });

        await tx.wait();

        const UUID = await saleUUIDPromise;
        await handleSaveFundraising(projectName, network, UUID)

        return UUID;
    } catch (error) {
        console.error('Error writing to smart contract:', error);
        return error;
    }
};

const handleSaveFundraising = async (projectName, network, saleIdx) => {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const selectedAccount = accounts[0];
    const data = {
        ownerAddress: selectedAccount,
        projectName: projectName,
        entries: [
            {
                network: network.name,
                address: saleIdx,
                // TODO: refactor if needed
                startDate: new Date(),
                endDate: new Date(),
                status: "PRE_SALE"
            }
        ]
    };
    try {
        const result = await saveFundraising(data);
        console.log('Fundraising saved successfully:', result);
    } catch (error) {
        console.error('Error saving fundraising:', error);
    }
};

export async function invest(amount, investmentTokenAddress, saleUUID, decimals) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const totalTokenAmountInWei = (amount * (10 ** Number(decimals))).toLocaleString()
        const totalTokenAmountInWeiNormalized = totalTokenAmountInWei.replace(/,/g, '')

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        const tx = await fundraisingContract.invest(totalTokenAmountInWeiNormalized, investmentTokenAddress, saleUUID);



        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
        return error;
    }
};

const handleSaveContributorEvent = async (contributorAddress, projectName, network, saleIdx) => {
    const data = {
        contributorAddress: contributorAddress,
        entries: [
            {
                network: network,
                eventIdx: saleIdx,
                projectName: projectName,
                eventType: "Fundraising",
                totalAmount: Number,
                startDate: new Date(),
                endDate: new Date()
            }
        ]
    };
    try {
        const result = await saveContributor(data);
        console.log('Fundraising saved successfully:', result);
    } catch (error) {
        console.error('Error saving fundraising:', error);
    }
};

export async function closeCampaign(saleUUID) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        const tx = await fundraisingContract.closeCampaign(saleUUID);

        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
};

export async function claimTokens(saleUUID) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        const tx = await fundraisingContract.claimTokens(saleUUID);

        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
        return error;
    }
};

export async function reclaimTokens(saleUUID) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        const tx = await fundraisingContract.reclaimTokens(saleUUID);

        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
};

export async function getWhitelistedTokens(saleIdx) {
    const erc20Addresses = []

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        erc20Addresses.push(await fundraisingContract.getWhitelistedTokens(saleIdx));

        return erc20Addresses;

    } catch (error) {
        console.error('Error while reading from smart contract:', error);
    }
}

export async function addWhitelistAddress(whitelistedAddresses, saleUUID) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        const tx = await fundraisingContract.addWhitelistAddress(whitelistedAddresses, saleUUID);

        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
};

export async function getIndividualBalanceForToken (saleIdx, erc20Address) {

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        return await fundraisingContract.getIndividualBalanceForToken(saleIdx, erc20Address);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getIndividualBalances (saleIdx) {

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        return await fundraisingContract.individualBalances(saleIdx, signer);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function computeReleasableAmount (saleIdx) {

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        return await fundraisingContract.computeReleasableAmount(saleIdx);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getReleasedTokens (saleIdx, erc20Address) {

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        return await fundraisingContract.released(saleIdx, erc20Address);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getChainlinkDataFeedLatestAnswer (erc20Address) {

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        return await fundraisingContract.getChainlinkDataFeedLatestAnswer(erc20Address);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getProjectsFundraisings(projectName, adminAddress) {

    const erc20Addresses = []

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        erc20Addresses.push(...await queryFundraisingsCreated(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, signer, projectName, adminAddress));

        return erc20Addresses;

    } catch (error) {
        console.error('Error while reading from smart contract:', error);
    }
}

export async function getReferralFees() {

    const referralFees = []

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        referralFees.push(...await queryReferralFees(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, signer));

        return referralFees;

    } catch (error) {
        console.error('Error while reading from smart contract:', error);
    }
}

async function queryFundraisingsCreated(factoryContractAddress, signer, projectName, adminAddress){
    let fundraisings = []
    const tokenFactoryContract = new ethers.Contract(factoryContractAddress, Fundraising, signer);
    const specificFilter = tokenFactoryContract.filters.FundraisingCreated(adminAddress, null, null);

    const matchedEvents = await tokenFactoryContract.queryFilter(specificFilter, null, "latest")
    for(const event of matchedEvents){
        const fundraisingInstance = event.args[1];
        const projectNameFromChain = event.args[2];
        if (projectNameFromChain === projectName){
            fundraisings.push(fundraisingInstance)
        }
    }
    return fundraisings;
}

async function queryReferralFees(factoryContractAddress, signer) {
    let referralFees = [];

    const FundraisingContract = new ethers.Contract(factoryContractAddress, Fundraising, signer);

    const specificFilter = FundraisingContract.filters.ReferralFeeSent(signer, null, null, null);

    const matchedEvents = await FundraisingContract.queryFilter(specificFilter, null, "latest");

    for (const event of matchedEvents) {
        const whitelistedAddress = event.args[0];
        const erc20TokenAddress = event.args[1];
        const projectname = event.args[2];
        const fee = event.args[3];

        const feeData = {
            whitelistedAddress,
            erc20TokenAddress,
            projectname,
            fee
        };
        referralFees.push(feeData);
    }
    return referralFees;
}

export async function getFundraisingInstance (fundraisingIdx) {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();
        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, provider);

        return await fundraisingContract.getFundraisingInstance(fundraisingIdx);

    } catch (error) {
        console.error('Error reading from smart contract:', error);
    }
}

export async function getVestingPlan (fundraisingIdx) {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();
        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, provider);

        return await fundraisingContract.getVestingPlan(fundraisingIdx);

    } catch (error) {
        console.error('Error reading from smart contract:', error);
    }
}

export default handleAddFundraising;
