import React, { useEffect, useState } from 'react';
import {Popover, message, Button} from 'antd';
import { BellTwoTone } from '@ant-design/icons'; // Ant Design Two-Tone Icon
import '../assets/scss/_notifications.scss';
import axios from "axios";
import { useAccount } from "wagmi";
import NotificationsMessage from "./NotificationsMessage";
import {useAuthenticate} from "../hooks/useAuthenticate";
import {shouldAuthenticate} from "../utils/shouldAuthenticate";

export default function Notifications() {
    const [anchorEl, setAnchorEl] = useState(null);
    const { address } = useAccount();
    const [notifications, setNotifications] = useState([]);
    const { authenticate, authStatus } = useAuthenticate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const getNotifications = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/secured/api/getReceivedNotifications`, {
                    params: { receiptAddress: address },
                });
                const notificationsFromDb = response.data.map((notification, index) => (
                    <NotificationsMessage key={index} notification={notification} />
                ));
                setNotifications(notificationsFromDb);
            } catch (error) {
                message.error("Failed to load notifications.");
            }
        };
        if (open) getNotifications();
        setIsAuthenticated(shouldAuthenticate)

    }, [open, address, authStatus]);

    return (
        <div>
            <div onClick={handleClick}>
                {/* Ant Design BellTwoTone Icon */}
                <BellTwoTone className="bell-icon" twoToneColor="#1A18FF" />
            </div>
            <Popover
                content={
                    <div className="notifications">
                        <div className="-header">
                            <div className='-title'>Notifications</div>
                        </div>
                        <div className="-navigation"></div>

                        {isAuthenticated ?
                            <Button style={{marginTop: '8px'}} htmlType="submit"
                                    onClick={() => authenticate(address)}>Sign message to get notifications</Button>
                            : notifications.length === 0 ?
                                <div>There are no notifications</div>
                                :
                                <div>{notifications}</div>
                        }
                    </div>
                }
                trigger="click"
                open={open}
                onOpenChange={open ? handleClose : handleClick}
                placement="bottom"
            />
        </div>
    );
}
